import React, { useState, ChangeEvent } from "react";
import Layout from "../components/layout";
import PageTitle from "../components/PageTitle";
import { TransitionContainer } from "../components/TransitionContainer";
import { Highlight } from "../components/Highlight";
import { Typography, Container, Box, Link } from "@material-ui/core";
import { graphql } from "gatsby";
import { mergeClasses } from "@material-ui/styles";

type IndexProps = {
  data: any;
};

export default ({ data }: IndexProps) => {
  return (
    <Layout>
      <PageTitle fluidImage={data.file.childImageSharp.fluid} height="100%">
        <Box py={30}>
          <TransitionContainer direction="start">
            <Typography variant="h3" component="h1" paragraph>
              Karriere
            </Typography>
            <Typography variant="h5" paragraph>
              Ich bin immer auf der Suche nach talentierten Kollegen, 
              welche die Arbeit menschlich als auch fachlich bereichern.
            </Typography>
            <Typography variant="h5" paragraph>
              Sende mir eine Kopie deines aktuellen Lebenslaufs samt 
              Anlagen an die folgende Adresse.
            </Typography>
            <Typography variant="h5" paragraph>
              <Link href="mailto:karriere@tajdari.de">karriere@tajdari.de</Link>
            </Typography>
          </TransitionContainer>
        </Box>
      </PageTitle>
    </Layout>
  );
};

export const query = graphql`
  query {
    file(relativePath: { eq: "karriere.jpg" }) {
      childImageSharp {
        fluid(quality: 90) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
  }
`;
